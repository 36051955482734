<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item navbar-brand-mini-wrapper">
          <a class="navbar-brand brand-logo-mini" href="#"><img src="@/assets/images/logo-mini.svg" alt="logo" /></a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <span class="menu-title">{{$t('sidebar.home')}}</span>
            <i class="mdi mdi-24px mdi-home menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/olcc">
            <span class="menu-title">{{$t('sidebar.olcc')}}</span>
            <i class="mdi mdi-24px mdi-connection menu-icon"></i>
          </router-link>

        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/pole">
            <span class="menu-title">{{$t('sidebar.pole')}}</span>
            <i class="mdi mdi-24px mdi-boom-gate-up menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/alerts">
            <span class="menu-title">{{$t('sidebar.alerts')}}</span>
            <i class="mdi mdi-24px mdi-alert menu-icon"></i>
          </router-link>
        <li class="nav-item" v-if="manager" v-on:click="collapseAll">
          <router-link class="nav-link" to="/users">
            <span class="menu-title">{{$t('sidebar.users')}}</span>
            <i class="mdi mdi-24px mdi-account-multiple menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/logs">
            <span class="menu-title">{{$t('sidebar.logs')}}</span>
            <i class="mdi mdi-24px mdi-clipboard-text-clock menu-icon"></i>
          </router-link>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'sidebar',
 data () {
    return {
      manager: false,
      collapses: [ 
     { show: false },
     { show: false },
     { show: false }
    ]
    }
  },

  methods: {
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted () {
    const permission = localStorage.getItem("zrd_manager")
    if (permission){this.manager = permission==="true"}
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  } 
}
</script>