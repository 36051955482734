import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'


Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages/ftf')
        }
      ]
    },
    {
      path: '/olcc',
      component: layout,
      children: [
        {
          path: '',
          name: 'config',
          component: () => import('@/pages/olcc')
        }
      ]
    },
    {
      path: '/pole',
      component: layout,
      children: [
        {
          path: '',
          name: 'license',
          component: () => import('@/pages/pole')
        }
      ]
    },
    {
      path: '/alerts',
      component: layout,
      children: [
        {
          path: '',
          name: 'cameras',
          component: () => import('@/pages/alerts')
        }
      ]
    },
    {
      path: '/logs',
      component: layout,
      children: [
        {
          path: '',
          name: 'user',
          component: () => import('@/pages/logs')
        }
      ]
    },
    {
      path: '/users',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://olcc-zufahrtssteuerungauth-test.zeppelin-rental.de/admin/FTF-ZRD/console/#/FTF-ZRD/groups/c5c819a1-5b8b-4bef-88ac-fa25c4d5346f")
      }
    },
    {
      path: '/logout',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://olcc-zufahrtssteuerungauth-test.zeppelin-rental.de/realms/FTF-ZRD/protocol/openid-connect/logout")
      }
    },
    {
      path: '/profile',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.open("https://olcc-zufahrtssteuerungauth-test.zeppelin-rental.de/realms/FTF-ZRD/account/")
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
